<template>
  <div class="nav-item dropdown ms-2" >
      <button type="button" class="btn btn-link nav-link  px-0 px-lg-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">   
        <span v-if="$i18n.locale =='gb'" >
          <flag :iso="$i18n.locale" /> En
        </span>
        <span v-else >
          <flag :iso="$i18n.locale" /> {{ $i18n.locale }}
        </span>
      </button>

      <ul class="dropdown-menu dropdown-menu-end">
        <li data-bs-dismiss="offcanvas" @click="switchLanguage" v-for="sLocale in supportedLocales" :key="`locale-${sLocale}`">
          <a class="dropdown-item" :name="sLocale">
            <flag :iso="sLocale"></flag> {{ t(`locale.${sLocale}`) }} 
        </a></li>
      </ul>
    </div>

    

  </template>
  
  <script>
   
    import { useI18n } from 'vue-i18n'
    import { useRouter } from "vue-router"
    import Tr from "@/i18n/translation"
    export default {
      name: 'LanguageSwitcher',
      data() {
        return {
          lang: "DE"
        }
      },
      setup() {
        const { t, locale } = useI18n()
        const supportedLocales = Tr.supportedLocales
        const router = useRouter()
        const switchLanguage = async (event) => {
          const newLocale = event.target.name

          await Tr.switchLanguage(newLocale) 
          try {
            await router.replace({ params: { locale: newLocale } })
          } catch(e) {
            router.push("/")
          }
        }
        return { t, locale, supportedLocales, switchLanguage }
      },
      
    }
  </script>