<template>
    <NavHeader/>
  <div class="wrapper d-flex justify-content-center mb-5 pb-5">
    <router-view v-slot="{ Component }">
      <transition
       enter-active-class="animate__animated animate__fadeIn"
       leave-active-class="animate__animated animate__fadeOut" 
       mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
  </div> 
  <NavFooter/>
</template>

<script>
import NavFooter from './components/NavFooter.vue'
import NavHeader from './components/NavHeader.vue'


export default {
  name: 'App',
  components: {
    NavHeader,
    NavFooter
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');


table tr td, table tr th{
    background-color: transparent !important;
}
.p-card-title{
    font-size: 1.3rem !important;
    font-weight: 500 !important;

  }

.pink-dark{
  color: #d30054;
}

#app{
  display:flex;
  flex-direction:column;
  min-height:100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.wrapper{
  flex:1;
}
body{
  margin:0px;
}
html, body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight:400;
}
.text-sm{
  font-size: 1rem;
}
.text-xs{
  font-size: .9rem;
}
.nav-link{
  opacity: .6 !important;
  text-transform:uppercase;
  letter-spacing: 2px;
}
.nav-link:hover{
  text-shadow:0px 0px 1px #d30054;
  color: #d30054 !important;
}
.btn-outline-orange{
  color: #ff9a65 !important;
  border: 2px solid #ff9a65 !important;
  border-radius: 4px !important;
}
.btn-outline-orange:hover {
    opacity: .75;
}
.pink-dark{
  color: #e0005a !important;
}
.modal-header{
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.modal-footer{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.link-disabled{
  color:#fff;
  text-decoration: none;
  display:block;
  padding: 0.5rem 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: .6;
}

@media (max-width: 1199px) {
    .nav-link {
        color: black !important; 
    }
    .nav-item .dropdown{
      margin: 0px !important;
    }
}

@media only screen and (max-width: 600px) {
  .img-resp {
    display:none;
  }
}

</style>
