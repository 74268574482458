<template>
  <Dialog v-model:visible="this.$store.state.modals.Login" modal :header="$t('login.header')" :style="{ width: '35vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :draggable="false">
    
  <!-- <ModalLongScroll :click-to-close="false" :esc-to-close="true" teleportTo = "body" v-model="this.$store.state.modals.Login" 
  class="position-absolute top-0 right-0 bottom-0 left-0 h-100 overflow-auto justify-content-center" 
  content-class="d-flex justify-content-center bg-white modal-lg mt-5"> -->
    <div ref="target" class="modal-content animate__animated animate__fadeInTop animate__fadeOutTop">   
      <Form @submit="submit" :validation-schema="schema" v-slot="{ errors }" class="m-3">
        <!-- <div class="modal-header mb-3">
          <h5 class="modal-title fw-bold">{{ $t('login.header') }}</h5>
          <button @click="closeModal('Login')" type="button" class="btn-close" aria-label="Close"></button>
        </div> -->
        <div class="modal-body p-2">
          <Message v-if="getStatusLoginMsg" severity="warn" :closable="false" :life="5000" :sticky="false">{{$t('err.loginRequired')}}</Message>  
      
          <Message v-if="msg.length > 0 & !msgStatus" severity="error">{{ msg }}</Message>
          <Message v-else-if="msg.length > 0 & msgStatus" severity="success">{{ msg }}</Message>
          <div class="mb-3">
            <label for="email">{{ $t("registration.email") }}</label>
            <Field name="email"  type="text"  class="form-control" :class="{ 'is-invalid': errors.email }"/>
            <div class="invalid-feedback">{{errors.email}}</div> 
          </div>
          <div class="mb-3">
            <label for="password">{{ $t("registration.password") }}</label>
            <div class="input-group">
              <Field v-if="showPassword" type="text" name="password" class="form-control" :class="{ 'is-invalid': errors.password }" />
              <Field v-else type="password" name="password" class="form-control" :class="{ 'is-invalid': errors.password }" />
              <span class="input-group-text" type="button" @click="togglePassword">
                <font-awesome-icon v-if="!showPassword" icon="fa-solid fa-eye" />
                <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
              </span>
              <div class="invalid-feedback">{{errors.password}}</div>
            </div>
            <div @click="openModal({modalName: 'Password'})" id="password" class="form-text">{{ $t("registration.forgotPassword") }}</div>
          </div>
        </div>
        <div class="modal-footer p-2 d-flex justify-content-lg-between justify-content-start">
          <div @click="openModal({modalName: 'Register'})" class="btn btn-outline-orange mb-2 me-1" id="registration-btn">{{ $t("registration.header") }}</div>
          <button type="submit" class="btn btn-outline-orange d-flex align-items-center mb-2">
            <span class="me-1">{{ $t("login.header") }}</span>
            <i class="pi pi-angle-double-right"></i>
          </button>
        </div>
      </Form>
    </div>
    </Dialog>
  <!-- </ModalLongScroll> -->
  

</template>

<script>
import { Form, Field} from 'vee-validate';
import * as Yup from 'yup';
import Tr from "@/i18n/translation"
import i18n from '@/i18n'
import axios from 'axios'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { mapGetters, mapActions } from 'vuex';
import ModalLongScroll from '@/components/ModalLongScroll.vue'

 export default {
      name: 'LoginModal',
      components:{
        Form,
        Field,
        ModalLongScroll
      },
      props: {
        loginMsg: Boolean
      },
      emits: [ "close"],
      setup(props, {emit}) {
        const target = ref(null);
        const handleClickOutside = () => {
          emit('close');
        };
    
        onClickOutside(target, handleClickOutside);
        
        return {  Tr, target };
      },
      computed: {
        ...mapGetters(['getStatusLoginMsg']),
      },
      data() { 
        const schema = Yup.object().shape({
            email: Yup.string().required(() => i18n.global.t('err.email')),
            password: Yup.string(() => i18n.global.t('err.password')).min(8, () => i18n.global.t('err.passwordLength')).required(() => i18n.global.t('err.passwordRequired'))
        });
        return {
          schema,
          msg: [],
          msgStatus: Boolean,
          showPassword: false,
        }
      },
      methods: {
        ...mapActions(['openModal', 'closeModal']),
        submit(values){

          axios
          .post('/rota/backend/login.php', values)
          .then(response => 
           {
            this.msg = i18n.global.t(response.data["msg"])
            this.msgStatus = response.data["msg-status"]

            if (this.msgStatus) {
              this.$store.dispatch('loginUser', {email: values.email})
            }
            })
          .catch(error => {
              console.log("login-error: ", error)
          })

        },
        togglePassword(){
          this.showPassword = !this.showPassword;
        }
      }
 }

</script>

<style>
#password:hover{
  text-decoration: underline;
  cursor: pointer;

}


</style>