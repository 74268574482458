<template>
  <!-- <ModalLongScroll   :click-to-close="false" :esc-to-close="true" teleportTo = "body" v-model="this.$store.state.modals.Register" 
  class="position-absolute top-0 right-0 bottom-0 left-0 h-100 overflow-auto justify-content-center"  tabindex="0" 
  content-class="d-flex justify-content-center bg-white modal-lg mt-5"> -->
  <Dialog v-model:visible="this.$store.state.modals.Register" modal :header="$t('registration.header')" :style="{ width: '45vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :draggable="false">
    
    <div ref="target" class="modal-dialog py-2 animate__animated animate__fadeInTop  animate__fadeOutTop">
      <Form class="modal-content" @submit="submit" :validation-schema="schema" v-slot="{ errors }">
        <!-- <div class="modal-header mb-3">
          <h5 class="modal-title fw-bold">{{ $t('registration.header') }}</h5>
          <button @click="closeModal('Registration')" type="button" class="btn-close" aria-label="Close"></button>
        </div> -->
      <div class="modal-body p-2">
        <Message v-if="msg.length > 0 & !msgStatus" severity="error">{{ msg }}</Message>
        <Message v-else-if="msg.length > 0 & msgStatus" severity="success">{{ msg }}</Message>
        
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="firstname">{{ $t("registration.firstname") }}</label>
                <Field type="text" name="firstname" class="form-control" :class="{ 'is-invalid': errors.firstname }"/>
                <div class="invalid-feedback">{{errors.firstname}}</div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="lastname">{{ $t("registration.lastname") }}</label>
                <Field type="text" class="form-control" name="lastname" :class="{ 'is-invalid': errors.lastname }"/>
                <div class="invalid-feedback">{{errors.lastname}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="affiliation">{{ $t("registration.affiliation") }}</label>
                <Field type="text" class="form-control" name="affiliation" :class="{ 'is-invalid': errors.affiliation }"/>
                <div class="invalid-feedback">{{errors.affiliation}}</div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="affiliation"></label>
                <Field name="country" v-model="country">
                  <country-select as="select" :usei18n="false" :placeholder="$t('registration.countrySelect')"
                    :class="{ 'is-invalid': errors.country }" class="form-select" v-model="country"
                    :country="country" :autocomplete="true" :disablePlaceholder="true"/>
                </Field>
                <div class="invalid-feedback">{{errors.country}}</div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="email">{{ $t("registration.email") }}</label>
              <Field type="email" class="form-control" name="email" :class="{ 'is-invalid': errors.email }" autocomplete="email"/>
              <div class="invalid-feedback">{{errors.email}}</div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="password">{{ $t("registration.password") }}</label>
                <div class="input-group">
                  <Field v-if="showPassword" type="text" class="form-control" name="password" :class="{ 'is-invalid': errors.password }"/>
                  <Field v-else type="password" class="form-control" name="password" :class="{ 'is-invalid': errors.password }"/>
                  <span class="input-group-text" type="button" @click="togglePassword">
                    <font-awesome-icon v-if="!showPassword" icon="fa-solid fa-eye" />
                    <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                  </span>
                </div>
                
                <div class="invalid-feedback">{{errors.password}}</div>
              </div>
              <div class="col-md-6 mb-3 ">
                <label for="confirmPassword">{{ $t("registration.reppassword") }}</label>
                <div class="input-group">
                  <Field v-if="showPassword" type="text" class="form-control" name="confirmPassword" :class="{ 'is-invalid': errors.confirmPassword }"/>
                  <Field v-else type="password" class="form-control" name="confirmPassword" :class="{ 'is-invalid': errors.confirmPassword }"/>

                  <span class="input-group-text" type="button" @click="togglePassword">
                    <font-awesome-icon v-if="!showPassword" icon="fa-solid fa-eye" />
                    <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                  </span>
                </div>
                
                <div class="invalid-feedback">{{errors.confirmPassword}}</div>
              </div>
            </div>
            
      </div>
      <div class="modal-footer p-2 d-flex justify-content-between">
          <div  @click="openModal({modalName: 'Login'})" class="btn btn-outline-orange" id="login-btn">Login</div>
          <button type="submit" class="btn btn-outline-orange d-flex align-items-center">
            <span class="me-1">{{ $t("registration.header") }}</span>
            <i class="pi pi-angle-double-right"></i>
        </button>
        </div>
      </Form>
    </div>
    </Dialog>
    
  <!-- </ModalLongScroll> -->
</template>

<script>
import { Form, Field} from 'vee-validate';
import * as Yup from 'yup';
import Tr from "@/i18n/translation"
import i18n from '@/i18n'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import {  mapActions } from 'vuex';
import ModalLongScroll from '@/components/ModalLongScroll.vue'

import axios from 'axios'

 export default {
      name: 'RegistrationModal',
      components:{
        Form,
        Field,
        ModalLongScroll
      },
      emits: ["close"],
      data() { 
        const schema = Yup.object().shape({
            firstname: Yup.string()
                .required(() => i18n.global.t('err.firstname')),
            lastname: Yup.string()
                .required(() => i18n.global.t('err.lastname')),
            affiliation: Yup.string()
                .required(() => i18n.global.t('err.affiliation')),
            country: Yup.string()
                .required(() => i18n.global.t('err.country')),
            email: Yup.string().required(() => i18n.global.t('err.email')),
            password: Yup.string().min(8, "Length").required(() => i18n.global.t('err.password')),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], () => i18n.global.t('err.confirmPassword'))
                .required(() => i18n.global.t('err.confirmPassword2')),
        });
        return {
          schema,
          msg: [],
          msgStatus: false,
          country: "",
          showPassword: false,
        }
      },
      setup(props, {emit}) {
        const target = ref(null);
        const handleClickOutside = () => {
          emit('close');
        };
    
        onClickOutside(target, handleClickOutside);
        
        return {  Tr, target};
      },
      methods: {
        ...mapActions(['openModal', 'closeModal']),
        submit(values){
          axios
          .post('/rota/backend/registration.php', values)
          .then(response => {
            this.msg = i18n.global.t(response.data["msg"])
            this.msgStatus = response.data["msg-status"]

            if (this.msgStatus) {
              this.$store.dispatch('loginUser', {email: values.email})
            }
            })
        },
        togglePassword(){
          this.showPassword = !this.showPassword;
        }
      }
 }

</script>

<style>


</style>