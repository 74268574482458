import { createI18n } from "vue-i18n"
import de from "./locales/de.json"

export default createI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: { de },
  runtimeOnly: false,

})