import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n" //-- Mehrsprachigkeit

//import { vfmPlugin } from 'vue-final-modal'
//import 'vue-final-modal/style.css'


//-- Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


//-- Registrierung: Flaggen-Symbole
import FlagIcon from 'vue-flag-icon'
import vueCountryRegionSelect from 'vue3-country-region-select'

//--Modale: Ein- und Ausblenden mit Richtung
import 'animate.css';

//- Form validation: Registrierung + Login + Password reset
import { Field, Form} from 'vee-validate'

// import the fontawesome core
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import specific icons and add to library
import { faPlus, faX, faHouse, faEnvelope, faChevronDown, faChevronUp, faTriangleExclamation, faSquare, faXmark, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
library.add(faPlus, faX, faHouse, faEnvelope, faChevronDown, faChevronDown, faChevronUp, faTriangleExclamation, faSquare, faXmark, faEye, faEyeSlash)

//-- VueX Stote allgemein einbinden
import store from './store'

//-- PrimeVue
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/lara-light-indigo/theme.css"; 
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

// Tabellen
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


//-- Elemente von PrimeVue
import Button from "primevue/button"
import Dialog from "primevue/dialog"
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import Chips from 'primevue/chips';
import Chip from 'primevue/chip';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Toolbar from 'primevue/toolbar';
import ProgressSpinner from 'primevue/progressspinner';
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import RadioButton from 'primevue/radiobutton';
import Card from 'primevue/card';
import Tag from 'primevue/tag';
import SelectButton from 'primevue/selectbutton';
import Listbox from 'primevue/listbox';
import Divider from 'primevue/divider';
import Tree from 'primevue/tree';



createApp(App).
  use(store).
  use(router).
  use(i18n).
  use(FlagIcon).
  use(ToastService).
  use(PrimeVue).
  use(vueCountryRegionSelect).
  //use(vfmPlugin).
  component('Divider', Divider).
  component('EasyDataTable', Vue3EasyDataTable).
  component('font-awesome-icon', FontAwesomeIcon).
  component('Form', Form).
  component('Field', Field).
  component('Button', Button).
  component('Dialog', Dialog).
  component('Dropdown', Dropdown).
  component('Message', Message).
  component('Chips', Chips).
  component('Chip', Chip).
  component('InputText', InputText).
  component('ToggleButton', ToggleButton).
  component('Toolbar', Toolbar).
  component('ProgressSpinner', ProgressSpinner).
  component('AutoComplete', AutoComplete).
  component('Calendar', Calendar).
  component('DataTable', DataTable).
  component('Column', Column).
  component('Tag', Tag).
  component('Tree', Tree).
  component('ColumnGroup', ColumnGroup).
  component('Row', Row).
  component('Toast', Toast).
  component('RadioButton', RadioButton).
  component('SelectButton', SelectButton).
  component("Card", Card).
  component("Listbox", Listbox).
  directive('tooltip', Tooltip).
  mount('#app')

