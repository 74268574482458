<template>
    <!-- Modal -->
    <div class="modal fade"  id="loginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('login.header')  }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <Form @submit="submit" :validation-schema="schema" v-slot="{ errors }" class="m-3">
            <div class="modal-body p-2 border-top">
              
              <Message v-if="getStatusLoginMsg" severity="warn" :closable="false" :life="5000" :sticky="false">{{$t('err.loginRequired')}}</Message>  
          
              <Message v-if="msg.length > 0 & !msgStatus" severity="error">{{ msg }}</Message>
              <Message v-else-if="msg.length > 0 & msgStatus" severity="success">{{ msg }}</Message>
              <div class="mb-3 mt-3">
                  <label for="email">{{ $t("registration.email") }}</label>
                  <Field name="email"  type="text"  class="form-control" :class="{ 'is-invalid': errors.email }" autocomplete="email"/>
                  <div class="invalid-feedback">{{errors.email}}</div> 
              </div>
              <div class="mb-3">
                  <label for="password">{{ $t("registration.password") }}</label>
                  <div class="input-group">
                  <Field v-if="showPassword" type="text" name="password" class="form-control" :class="{ 'is-invalid': errors.password }" autocomplete="password"/>
                  <Field v-else type="password" name="password" class="form-control" :class="{ 'is-invalid': errors.password }" autocomplete="password"/>
                  <span class="input-group-text" type="button" @click="togglePassword">
                      <font-awesome-icon v-if="!showPassword" icon="fa-solid fa-eye" />
                      <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                  </span>
                  <div class="invalid-feedback">{{errors.password}}</div>
                  </div>
                  <div data-bs-toggle="modal" data-bs-target="#passwordModal" id="password" class="form-text">{{ $t("registration.forgotPassword") }}</div>
              </div>
            </div>
            <div class="modal-footer p-2 d-flex justify-content-lg-between justify-content-start">
              <div data-bs-toggle="modal" data-bs-target="#registrationModal"  class="btn btn-outline-orange mb-2 me-1 mt-3" id="registration-btn">{{ $t("registration.header") }}</div>
              <button  type="submit" class="btn btn-outline-orange d-flex align-items-center mb-2 mt-3">
                  <span class="me-1">{{ $t("login.header") }}</span>
                  <i class="pi pi-angle-double-right"></i>
              </button>
            </div>
        </Form>
        </div>
    </div>

              
  </div>
</template>




<script>
import { Form, Field} from 'vee-validate';
import * as Yup from 'yup';
import Tr from "@/i18n/translation"
import i18n from '@/i18n'
import axios from 'axios'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { mapGetters, mapActions } from 'vuex';
import ModalLongScroll from '@/components/ModalLongScroll.vue'

 export default {
      name: 'LoginModal',
      components:{
        Form,
        Field,
        ModalLongScroll
      },
      props: {
        loginMsg: Boolean
      },
      emits: [ "close"],
      setup(props, {emit}) {
        const target = ref(null);
        const handleClickOutside = () => {
          emit('close');
        };
    
        onClickOutside(target, handleClickOutside);
        
        return {  Tr, target };
      },
      computed: {
        ...mapGetters(['getStatusLoginMsg']),
      },
      data() { 
        const schema = Yup.object().shape({
            email: Yup.string().required(() => i18n.global.t('err.email')),
            password: Yup.string(() => i18n.global.t('err.password')).min(8, () => i18n.global.t('err.passwordLength')).required(() => i18n.global.t('err.passwordRequired'))
        });
        return {
          schema,
          msg: [],
          msgStatus: Boolean,
          showPassword: false,
        }
      },
      methods: {
        ...mapActions(['openModal', 'closeModal']),
        submit(values){

          axios
          .post('/rota/backend/login.php', values)
          .then(response => 
           {
            this.msg = i18n.global.t(response.data["msg"])
            this.msgStatus = response.data["msg-status"]

            if (this.msgStatus) {
              this.$store.dispatch('loginUser', {email: values.email})
            }
            })
          .catch(error => {
              console.log("login-error: ", error)
          })

        },
        togglePassword(){
          this.showPassword = !this.showPassword;
        }
      }
 }

</script>

<style>
#password:hover{
  text-decoration: underline;
  cursor: pointer;

}


</style>