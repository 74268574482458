<template>
    <VueFinalModal
      content-transition="vfm-fade"
      overlay-transition="vfm-fade"
      :content-class="modalContentClass"
      @update:model-value="onModalUpdate"
    >
      <div class="position-absolute top-0 right-0 bottom-0 left-0 h-100 overflow-auto justify-content-center" @click.self="closeModal">
        <div class="d-flex justify-content-center max-width-xl my-3 mx-auto px-4 py-4 bg-white rounded">
          <slot />
        </div>
      </div>
    </VueFinalModal>
  </template>
  
  <script>
  import { defineComponent, ref, computed } from 'vue';
  import { VueFinalModal } from 'vue-final-modal';
  
  export default defineComponent({
    components: {
      VueFinalModal,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    setup(props, context) {
      const modalVisible = ref(false);
  
      const modalContentClass = computed(() => ({
        'absolute': true,
        'inset-0': true,
      }));
  
      const closeModal = () => {
        modalVisible.value = false;
        context.emit('update:modelValue', false);
      };
  
      const onModalUpdate = (val) => {
        modalVisible.value = val;
      };
  
      const confirm = () => {
        closeModal();
        context.emit('confirm');
      };
  
      return {
        modalContentClass,
        closeModal,
        onModalUpdate,
        confirm,
      };
    },
  });
  </script>