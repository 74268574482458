<template>
    <!-- Modal -->
    <div class="modal fade" id="passwordModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('registration.resetPassword') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  
                    <Form class="modal-body" @submit="submit" :validation-schema="schema" v-slot="{ errors }" >
                      <Message v-if="msg.length > 0 && !msgStatus" severity="error">{{ msg }}</Message>
                      <Message v-if="msg.length > 0 && msgStatus" severity="success">{{ msg }}</Message>

                    <div class="border-top p-2">
                        <div class="my-3">
                        <label for="email">{{ $t("registration.email") }}</label>
                        <Field type="email" class="form-control" name="email" :class="{ 'is-invalid': errors.email }" autocomplete="email"/>
                        <div class="invalid-feedback">{{errors.email}}</div>
                        
                        </div>
                    </div>
                    <div class="modal-footer p-2 d-flex justify-content-between">
                        <div data-bs-toggle="modal" data-bs-target="#registrationModal" class="btn btn-outline-orange mb-2 me-1 mt-3" id="registration-btn">{{ $t("registration.header") }}</div>

                        <button type="submit" class="btn btn-outline-orange d-flex align-items-center mb-2 mt-3">
                        <span class="me-1">Reset</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    </div>
                    </Form>

                </div>
              </div>
            </div>
</template>


<script>
import { Form, Field} from 'vee-validate';
import * as Yup from 'yup';
import Tr from "@/i18n/translation"
import i18n from '@/i18n'
import axios from 'axios'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { mapGetters, mapActions } from 'vuex';
import ModalLongScroll from '@/components/ModalLongScroll.vue'

 export default {
      name: 'passwordModal',
      components:{
        Form,
        Field,
        ModalLongScroll
      },
      emits: ["close"],
      setup(props, {emit}) {
        const target = ref(null);
        const handleClickOutside = () => {
          emit('close');
        };
    
        onClickOutside(target, handleClickOutside);
        
        return {  Tr, target };
      },
      data() { 
        const schema = Yup.object().shape({
          email: Yup.string().required(() => i18n.global.t('err.email')),
        });
        return {
          schema,
          msg: "",
          msgStatus: false,
        }
      },
      methods: {
        ...mapActions(['openModal', 'closeModal']),
        submit(values){
          values.lang= this.$i18n.locale
          

          axios
          .post('/rota/backend/resetPassword.php', values)
          .then(response => 
           {
            this.msg = i18n.global.t(response.data["msg"])
            this.msgStatus = response.data["msg-status"]
            if(this.msgStatus){

              setTimeout(() => { // Reload um Login Fenster zu schließen
              window.location.reload();
            }, 500);
              
              setTimeout(() => {
                this.$store.commit('setModalOpen', { modalName: "Password", modalState: false });
              }, 6000);
            }
            
            })
          .catch(e => {this.errors.push(e)});
        },
        

      }
 }

</script>

<style>
#password:hover{
  text-decoration: underline;
  cursor: pointer;

}


</style>