<template>
    <footer class="py-3 my-4">
        <ul class="nav justify-content-center border-bottom pb-3 mb-3">
            <li class="nav-item"><RouterLink :to="Tr.i18nRoute({ name: 'home' })" class="nav-link px-2 text-muted">Home</RouterLink></li>
            <!-- <li class="nav-item"><RouterLink :to="Tr.i18nRoute({ name: 'about' })" class="nav-link px-2 text-muted">{{ $t("about.header") }}</RouterLink></li> -->
            <li class="nav-item"><RouterLink :to="Tr.i18nRoute({ name: 'imprint' })" class="nav-link px-2 text-muted">{{ $t("imprint.header") }}</RouterLink></li>
            <li class="nav-item"><a :href="$t('datenschutz.link')" target="_blank" class="nav-link px-2 text-muted">{{ $t("datenschutz.header") }}</a></li>
        </ul>
        <p class="text-center text-muted">© 2023 JurisRota</p>
  </footer>

</template>

<script>
import Tr from "@/i18n/translation"

export default {
  name: 'NavFooter',
  setup() {
      return { Tr}
    }
}
</script>

<style>

</style>