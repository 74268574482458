import { createStore } from 'vuex'

import router from '../router'; //-- Für Umleitung bei Logout auf "/"

export default createStore({
  state: {
    email: null,
    loginTime: null, //-- Für automatischen Logout
    storeRestored: false,

    modals: { //-- Handling der Modale
      Login: false,
      Register: false,
      Password: false,
    },  
    LoginMsg: false, // Status: Nachricht das Login für Nutzung der Suche erforderlich
    autoLogoutTimer: null,
  },

  getters: {
    isModalOpen: (state) => (modalName) => {
      return state[modalName];
    },
    getStatusLoginMsg: (state) => {
      return state.LoginMsg;
    },
    isLoggedIn(state){
      if(state.email != null){
        return true
      }else{{
        return false
      }}
      
    },
  },

  mutations: {
    
    clearMessage(state) {
      state.LoginMsg = false
    },
    setModalOpen(state, { modalName, modalState, msgState }) {
      if (msgState === null || msgState === undefined) {
        state.LoginMsg = false;
      }else{
        state.LoginMsg = msgState

      }

      state.modals[modalName] = modalState;
    },
    setOtherModalsClosed(state, modalName) {
      Object.keys(state.modals).forEach(key => {
        if (key !== modalName) {
          state.modals[key] = false;
        }
      });
    },
    
    LOGIN(state, email) {
      state.email = email
      state.loginTime = Date.now() + 24 * 60 * 60 * 1000; // 1 Tag in Millisekunden
    },
    LOGOUT(state){
      state.email = null;
      localStorage.removeItem('email');
      router.push('/') 
    },
    SET_STORE_RESTORED_FLAG(state){
      state.storeRestored = true;
    },
    stopAutoLogout(state) {
      clearTimeout(state.autoLogoutTimer);
    },
    startAutoLogout(state) {
      const timeout = 3 * 60 * 60 * 1000; // 1 hour 
      state.autoLogoutTimer = setTimeout(() => {
        state.email = null;
        localStorage.removeItem('email');
      }, timeout);
    },

    openLoginModal (state, val) {
      state.LoginModal = val
    },

  },
  actions: {
    closeAllModals({ commit, state }) {
      commit('setModalOpen', {modalName: "Login", modalState: false });
      commit('setModalOpen', { modalName: "Register", modalState: false });
      commit('setModalOpen', { modalName: "Password", modalState: false });
    },
    closeModal({ commit, state }, modalName) {
      Object.keys(state.modals).forEach(key => {
        if (key !== modalName) {
          commit('setModalOpen', { modalName: key, modalState: false });
        }
      });
      commit('setModalOpen', { modalName, modalState: false });
    },
    openModal({ commit, state }, {modalName,  msg}) {
      if (msg === null || msg=== undefined) {
        msg = false
      }

      Object.keys(state.modals).forEach(key => {
        if (key !== modalName) {
          commit('setModalOpen', { modalName: key, modalState: false });
        }
      });
      if(modalName == "Login"){
        setTimeout(() => {
          commit('clearMessage');
        }, 5000);
      }
      commit('setModalOpen', { modalName: modalName, modalState: true, msgState: msg});
    },
    async loginUser({ commit, }, {email}) {
      commit('LOGIN', email)


      setTimeout(() => { // Reload um Login Fenster zu schließen
        window.location.reload();
      }, 500);
      

      setTimeout(() => {
        commit('setModalOpen', { modalName: "Login", modalState: false });
        commit('setModalOpen', { modalName: "Register", modalState: false });
      }, 1000);
      
    },
    async logoutUser({ commit }) {
      commit('stopAutoLogout')
      commit('LOGOUT')
    }
    

  },
  modules: {
  },
  plugins: [
    (store) => {
      // Speichert den Zustand des Stores im Local Storage, bevor die Seite neu geladen wird
      window.addEventListener('beforeunload', () => {
        localStorage.setItem('store', JSON.stringify(store.state));

      });
      
    window.addEventListener('DOMContentLoaded', () => {
      if (localStorage.getItem('store')) {
        store.replaceState(JSON.parse(localStorage.getItem('store')));
        const loginTime = store.state.loginTime; //-- Prüft ob LoginTime schon expired
        if (loginTime && loginTime < Date.now()) {
          store.commit('LOGOUT');
        }
      }

      store.commit('SET_STORE_RESTORED_FLAG');

    });
    }


  ]
})
