<template>

  <div  class="px-5 py-3 d-flex align-items-center d-none d-md-flex d-lg-flex d-xl-flex" rel="preload" :style="{'background-image': 'url(' + require('@/assets/books2.jpg') + ')'}" id="topper">
    <RouterLink class="text-decoration-none pink ms-4" id="top" :to="Tr.i18nRoute({ name: 'home' })">
        <h2><i class="text-white fw-normal shd">Juris</i><span class="fw-bold pink-dark shd">Rota</span></h2>
        <h4 class="subtitle text-white shd text-break">Online-Suche für Judikatur der Römischen Rota</h4>
    </RouterLink>
  </div>

  <nav class="navbar navbar-expand-lg" id="header">
  <div class="container-fluid">
    <div class="col-sm-10 col-md-10 d-flex align-items-center d-md-none d-lg-none d-xl-none">
      <RouterLink class="text-decoration-none pink ms-4 " :to="Tr.i18nRoute({ name: 'home' })">
        <h3><i class="text-white fw-normal">Juris</i><span class="fw-bold pink-dark">Rota</span></h3>
        <h5 class="text-sm fw-semibold pink-dark fw-light">Online-Suche für Judikatur der Römischen Rota</h5>
      </RouterLink>
    </div>
    
    <div class="col d-flex justify-content-end">
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    

    <div class="offcanvas offcanvas-end offcanvas-size-sm " tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title fw-semibold" id="offcanvasNavbarLabel">{{ $t("nav.offcanvasTitle") }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">

          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#"></a>
          </li>
          <li class="nav-item me-2" data-bs-dismiss="offcanvas" :disabled="!isLoggedIn">
            <RouterLink  :class="{ 'link-disabled': !isLoggedIn, 'nav-link': isLoggedIn}"  :to="Tr.i18nRoute({ name: 'search' })">{{ $t("search.header") }}</RouterLink>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ $t("über.header") }}
            </a>
            <ul class="dropdown-menu">
              <li data-bs-dismiss="offcanvas"><RouterLink class="dropdown-item" :to="Tr.i18nRoute({ name: 'persons' })">{{ $t("persons.header") }}</RouterLink></li>
              <li data-bs-dismiss="offcanvas"><RouterLink class="dropdown-item" :to="Tr.i18nRoute({ name: 'contact' })">{{ $t("contact.header") }}</RouterLink></li>
              <li data-bs-dismiss="offcanvas"><RouterLink class="dropdown-item" :to="Tr.i18nRoute({ name: 'imprint' })">{{ $t("imprint.header") }}</RouterLink></li>
              <li data-bs-dismiss="offcanvas"><a class="dropdown-item" target="_blank" :href="$t('datenschutz.link')">{{ $t("datenschutz.header") }}</a></li>
            </ul>
          </li>
    
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <button data-bs-toggle="modal" data-bs-target="#loginModal" v-if="!isLoggedIn" type="button" class="btn nav-link">Login</button> <!--@click="openModal({modalName: 'Login'})" -->
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <button data-bs-toggle="modal" data-bs-target="#registrationModal" v-if="!isLoggedIn" type="button" class="btn nav-link">{{ $t("registration.header") }}</button> <!-- @click="openModal({modalName: 'Register'})"-->
          </li>
          <li class="nav-item">
            <LanguageSwitcher/>
          </li>
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <button v-if="isLoggedIn" @click="logout" type="button" class="btn btn-logout">Logout</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
  


  
    <!-- <LoginModal @close="closeModal('Login')"/>
    <RegistrationModal @close="closeModal('Registration')"/>
    <ForgotPasswordModal @close="closeModal('Password')"/> -->
    

    <LoginModal2/>
    <RegistrationModal2/>
    <ForgotPasswordModal2/>



</template>

<script>

import Tr from "@/i18n/translation"
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import LoginModal from "@/components/LoginModal.vue";
import RegistrationModal from "@/components/RegistrationModal.vue";
import ForgotPasswordModal from "@/components/ForgotPasswordModal.vue";

import LoginModal2 from "@/components/LoginModal2.vue";
import RegistrationModal2 from "@/components/RegistrationModal2.vue";
import ForgotPasswordModal2 from "@/components/ForgotPasswordModal2.vue";


import { mapGetters, mapActions } from 'vuex';



export default {
  name: 'NavHeader',
  components: {
    LanguageSwitcher,
    LoginModal,
    RegistrationModal,
    ForgotPasswordModal,

    LoginModal2,
    RegistrationModal2,
    ForgotPasswordModal2,
    
  },
  computed: {
    ...mapGetters(['isLoggedIn', "isModalOpen",'getStatusLoginMsg']),
  },
  data() {
    return {
      dialog:false,
      modalRegister: this.$store.state.modals.Register, 
      modalLogin: this.$store.state.modals.Login, 
      modalPassword: this.$store.state.modals.Password, 
    };
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),
    logout(){
      this.$store.dispatch('logoutUser')
    },
    preventPageScroll(event) {
      event.preventDefault();
    },
  },
    setup() {
      
      return {Tr};
  },
}
</script>


<style scoped>

.vfm--overlay{
  background-color: #00000040 !important;
}
.shd{
  text-shadow: 1px 1px 1px #814d3a, 0 0 1px #814d3a;
}
.subtitle{
  font-weight: 400;
  font-size: 1.3em !important;
  letter-spacing: .05em;
}
 #topper{
  background: #f97a76;
  background-image: linear-gradient(-90deg, #ffa45d, #f97a76); 
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
} 

#header{
  background-color: #ffc2a1;
  padding: 0.75rem 0;
  background-image: linear-gradient(-90deg, #ffa45d, #f97a76);
  box-shadow: 0 0.5rem 1rem #ffc2a1b3, inset 0 -1px 0 #ffc2a1;
}


.pink{
  color: #ff0065;
  
}
.btn-logout{
  background-color: #ff0065;
  color: white;
}
.btn-logout:hover{
  background-color:  #d30054;
  color: white !important;

}

.navbar-brand:hover > :first-child{
  color: #fa4e93;
}
.navbar-brand:hover > :nth-child(2){
  color: #d30054;
}

.offcanvas-size-sm {
    --bs-offcanvas-width: min(95vw, 250px) !important;
}
@media screen and (max-width: 995px) {
  .nav-link{
    color: #6c757d !important;
}
}

@media screen and (min-width: 996px) {
  .nav-link{
    color: #fff !important;
  }
}

</style>