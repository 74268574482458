<template>
    <!-- <ModalLongScroll :click-to-close="false" :esc-to-close="true" teleportTo="body" v-model="this.$store.state.modals.Password" 
    class="position-absolute top-0 right-0 bottom-0 left-0 h-100 overflow-auto justify-content-center" tabindex="0" 
    content-class="d-flex justify-content-center bg-white modal-lg mt-5"> -->
  <Dialog v-model:visible="this.$store.state.modals.Password" modal :header="$t('registration.resetPassword')" :style="{ width: '35vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :draggable="false">
    
  <div ref="target" class="modal-content animate__animated animate__fadeInTop  animate__fadeOutTop">
    <Message v-if="msg.length > 0 && !msgStatus" severity="error">{{ msg }}</Message>
    <Message v-if="msg.length > 0 && msgStatus" severity="success">{{ msg }}</Message>
    <Form class="modal-content" @submit="submit" :validation-schema="schema" v-slot="{ errors }" >
      <!-- <div class="modal-header mb-3">
      <h5 class="modal-title fw-bold me-4">{{ $t('registration.resetPassword') }}</h5>
      <button @click="closeModal('Password')" type="button" class="btn-close" aria-label="Close"></button>
    </div> -->
      <div class="modal-body p-2">
        <div class="mb-3">
          <label for="email">{{ $t("registration.email") }}</label>
          <Field type="email" class="form-control" name="email" :class="{ 'is-invalid': errors.email }"/>
          <div class="invalid-feedback">{{errors.email}}</div>
          
        </div>
      </div>
      <div class="modal-footer p-2 d-flex justify-content-between">
        <div @click="openModal({modalName: 'Register'})" class="btn btn-outline-orange mb-2 me-1" id="registration-btn">{{ $t("registration.header") }}</div>

        <button type="submit" class="btn btn-outline-orange d-flex align-items-center mb-2">
          <span class="me-1">Reset</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
          <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
      </div>
    </Form>
    
  </div>
  </Dialog>
<!-- </ModalLongScroll> -->

</template>

<script>
import { Form, Field} from 'vee-validate';
import * as Yup from 'yup';
import Tr from "@/i18n/translation"
import i18n from '@/i18n'
import axios from 'axios'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { mapGetters, mapActions } from 'vuex';
import ModalLongScroll from '@/components/ModalLongScroll.vue'

 export default {
      name: 'passwordModal',
      components:{
        Form,
        Field,
        ModalLongScroll
      },
      emits: ["close"],
      setup(props, {emit}) {
        const target = ref(null);
        const handleClickOutside = () => {
          emit('close');
        };
    
        onClickOutside(target, handleClickOutside);
        
        return {  Tr, target };
      },
      data() { 
        const schema = Yup.object().shape({
          email: Yup.string().required(() => i18n.global.t('err.email')),
        });
        return {
          schema,
          msg: "",
          msgStatus: false,
        }
      },
      methods: {
        ...mapActions(['openModal', 'closeModal']),
        submit(values){
          values.lang= this.$i18n.locale
          

          axios
          .post('/rota/backend/resetPassword.php', values)
          .then(response => 
           {
            this.msg = i18n.global.t(response.data["msg"])
            this.msgStatus = response.data["msg-status"]
            if(this.msgStatus){
              
              setTimeout(() => {
                this.$store.commit('setModalOpen', { modalName: "Password", modalState: false });
              }, 6000);
            }
            
            })
          .catch(e => {this.errors.push(e)});
        },
        

      }
 }

</script>

<style>
#password:hover{
  text-decoration: underline;
  cursor: pointer;

}


</style>