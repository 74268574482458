<template>
      <div class="w-100">
        <div class="px-4 pt-5 mt-5 pb-2 w-100 " >
        <h1 class=" mt-5 text-center">{{ $t("home.header") }} <i class=" fw-semibold">Juris</i><span class="pink-dark fw-semibold">Rota</span> ?</h1>
        <div class="container-lg my-5">
          <div class="col-xl-8 col-lg-8 mx-auto my-5 text-center">
          <p class="text-lg mb-4">{{ $t("home.startText") }}
          </p>
          <div class="d-grid gap-4 d-sm-flex justify-content-sm-center pt-3">
            <Button v-if="!isLoggedIn" data-bs-toggle="modal" data-bs-target="#registrationModal"  label="Sign Up"  severity="warning" raised  /> <!-- @click="openModal({modalName: 'Register'})"-->
            <Button v-if="!isLoggedIn" data-bs-toggle="modal" data-bs-target="#loginModal" label="Log In"  raised  severity="warning" outlined /> <!-- @click="openModal({modalName: 'Login'})"-->
          </div>
        </div>
        </div>
      </div>

      

    <div id="home-text">
      <div class="container-fluid px-4 py-5 my-5">
      <div class="row d-flex align-items-center justify-content-center py-5 px-lg-5 px-md-5">
        <div class="col-xl-6 col-lg-6">
          <h1 class=" lh-1 mb-3">{{ $t("home.useTitle") }}</h1>
          <p class="text-lg">{{ $t("home.use1") }} <br> <br>
            {{ $t("home.use2") }}
        </p>
        <table class="table mb-5 pb-5">

        <tbody>
          <tr>
            <td>{{ $t("home.useTable1") }}</td>
            <td>{{ $t("home.useTable11") }}</td>
          </tr>
          <tr>
            <td>{{ $t("home.useTable2") }}</td>
            <td>{{ $t("home.useTable21") }}</td>
          </tr>
          <tr>
            <td>{{ $t("home.useTable3") }}</td>
            <td>{{ $t("home.useTable31") }}</td>
          </tr>
          <tr>
            <td>{{ $t("home.useTable4") }}</td>
            <td >{{ $t("home.useTable41") }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table mb-5 pb-5">
            <h5>{{ $t("home.useTitle2") }}</h5>

            <tbody>
              <tr>
                <td>{{ $t("home.useTable5") }}</td>
                <td>{{ $t("home.useTable51") }}</td>
              </tr>
              <tr>
                <td>{{ $t("home.useTable6") }}</td>
                <td>{{ $t("home.useTable61") }}</td>
              </tr>

            </tbody>
         </table>
        
        </div>
        <div class="col-xl-6 col-md-12 col-sm-12 col-lg-6">
          <p class="text-lg" v-html="$t('home.bottomText')"></p> 
          <p class="text-lg" ><a href="mailto:Sekretariat.berkmann@kaththeol.uni-muenchen.de" class="link-secondary text-break">Sekretariat.berkmann@kaththeol.uni-muenchen.de</a></p>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-5">
            <RouterLink :to="Tr.i18nRoute({ name: 'search' })" :disabled="!isLoggedIn" :class="{ 'link-disabled': !isLoggedIn, 'nav-link': isLoggedIn}" class="px-2 text-muted">{{ $t("home.toSearch") }} <i class="pi pi-arrow-right ms-2"></i></RouterLink>
          </div>
          

        </div>
        
      </div>
    </div>
      
    </div>

   
    
   
      </div>
</template>

<script>
import Tr from "@/i18n/translation"
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "HomeView",
  setup() {
      return {Tr};
  },
  data() {
    return { 
      modalOpen: false,
      visible2: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', "isModalOpen"]),
  },
  setup() {
      return { Tr}
    },
  
  methods: {
    ...mapActions(['openModal']),
    openLoginModal(){
      this.$store.state.LoginModal = true
    },
    openRegisterModal(){
      this.$store.state.RegisterModal = true
    }
    
  }

}

</script>


<style>
#home-text{
  background: linear-gradient(179deg, rgba(255, 255, 255, 1) 0%, #fab57f2b 50%, #ff9b8e0d 100%);
}

/* .modal div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 300px;
  height: 300px;
  padding: 5px;
} */

.text-lg{
  font-size: 1.1em;
  font-weight: 400;
}

@media only screen and (min-width: 1700px) {
  #home-text {
    padding-left: 150px;
    padding-right: 150px;
  }
}

</style>
